import ApiService from "@/core/services/api.service";
import store from "@/core/services/store";
let url = process.env.VUE_APP_API_TENANT;
if (process.env.VUE_APP_VERSION >= 2) {
  url = process.env.VUE_APP_API_ADMIN;
}

ApiService.setHeader("x-api-key", store.getters.apiToken);
if (store.getters.apiTokenPartner) {
  ApiService.setHeader("x-partner-key", store.getters.apiTokenPartner);
}

class TextTemplates {
  get(id) {
    return ApiService.get(url, "text_templates/" + id);
  }
  execute(id, data, template = null) {
    return ApiService.post(url + "/text_templates/" + id + "/execute", {
      data: data,
      template: template
    });
  }
  getAll(params) {
    let options = new URLSearchParams(params).toString();
    return ApiService.get(url, "text_templates?" + options);
  }
  update(id, data) {
    return ApiService.put(url + "/text_templates/" + id, data);
  }
  store(data) {
    return ApiService.post(url + "/text_templates", data);
  }
  delete(id) {
    return ApiService.delete(url + "/text_templates/" + id);
  }
}
export default new TextTemplates();
